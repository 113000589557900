<template>
  <b-container class="bet-warning">
    <b-card-group dec>
      <b-card class="card-border-bottom">
        <b-row class="mb-3">
          <b-col cols="1" class="d-sm-none">
            <button-back link="/report"></button-back>
          </b-col>
          <b-col cols="6" class="text-center">
            <b-button size="sm" variant="success" v-on:click="copySms"
              >Copy tin chạy số</b-button
            >
          </b-col>
          <b-col cols="5" class="mb-2 text-left">
            <b-form-select size="sm" v-model="game">
              <b-form-select-option :value="-1">Tất cả</b-form-select-option>
              <b-form-select-option :value="1">Bắc</b-form-select-option>
              <b-form-select-option :value="2">Trung</b-form-select-option>
              <b-form-select-option :value="3">Nam</b-form-select-option>
            </b-form-select>
          </b-col>
        </b-row>
        <div>
          <div
            id="sms-editor"
            class="mb-2 m-input"
            style="
              text-align: left;
              border: 1px solid #ced4da;
              border-radius: 0.25rem;
              min-height: 130px;
              word-wrap: break-word;
            "
            contentEditable="true"
          ></div>
        </div>
      </b-card>
      <b-card>
        <b-table striped hover :items="datas"></b-table>
      </b-card>
    </b-card-group>
  </b-container>
</template>
<script>
import moment from "moment";
export default {
  data() {
    return {
      sms: "",
      today: moment().format("DD-MM-YYYY"),
      datas: [],
      game: -1,
    };
  },
  created() {
    this.game = this.$route.query.game ? this.$route.query.game : -1;
    this.initWarning();
  },
  mounted() {
    let vm = this;

    document.getElementById("sms-editor").addEventListener(
      "input",
      () => {
        vm.sms = document.getElementById("sms-editor").innerText;
      },
      false
    );
  },
  watch: {
    game: async function(newVal) {
      await this.$nextTick();
      this.$router.replace({
        name: this.$router.history.current.name,
        params: this.$router.history.current.params,
        query: {
          ...this.$router.history.current.query,
          game: newVal,
        }
      });
      this.initWarning();
    }
  },
  methods: {
    async initWarning() {
      let vm = this;

      let res = await this.$http.get("/bets/warning?game="+this.game);

      if (res.data.code == 0) {
        vm.sms = res.data.sms;
        vm.datas = res.data.data;
        document.getElementById("sms-editor").innerText = res.data.sms;
      }
    },
    copySms() {
      let vm = this;

      vm.$copyText(vm.sms).then(
        function () {
          vm.makeToast("Thành công !", "success", true);
        },
        function () {
          vm.makeToast("Ko thành công !", "danger", true);
        }
      );
    },
    makeToast(content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: "Copy tin",
        autoHideDelay: 5000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5",
      });
    },
  },
};
</script>
<style>
.bet-warning.container {
  padding: 10px;
}
.bet-warning .card {
  border: none;
  border-radius: unset;
}
.bet-warning .card-border-bottom {
  border-bottom: 2px solid rgba(0, 0, 0, 0.125);
}
.bet-warning .table {
  margin-bottom: 0;
}
.bet-warning .table th,
.bet-warning .table td {
  border-top: none;
}
.bet-warning .card-body {
  padding: 0.5rem;
}
.bet-warning .table thead th {
  border-bottom: 1px solid #dee2e6;
}

.bet-warning .card-group > .card {
  margin-bottom: 0;
}
</style>
